import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Banking Website Template | Banking Website Design | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/online-banking-web-app/"
    metaDescription="Looking for your next banking wesbite template? Design your own e-banking web app with Uizard today and fast-track your design flow."
    description="
    h2:Introducing Plutus; the online banking website template
    <br/>
    Looking for an e-banking web app template for your design project? Say hello to Plutus; by Uizard. This online banking template has been designed with a clean and crisp interface and contains built-in, streamlined user flows. Simply sign up to Uizard and open the <a:https://uizard.io/templates/>UI template</a> to bring your own e-banking web app design to life.
    <br/>
    h3:A banking app template for personal finance, investments, and insurance
    <br/>
    Whatever design project you are working on, Plutus is infinitely customizable to suit any use case. You can drag and drop components, update user journeys, add and remove screens, and you can even change the entire theme or color profile of the template to suit your own brand vision or aesthetic.
    <br/>
    h3:Unlock your design potential with Uizard, the AI-powered design tool
    <br/>
    As with all our templates, Plutus is designed for the digital age and is easy to use. With Uizard, there's no need to go on your e-banking web app <a:https://uizard.io/prototyping/>UI prototyping</a> journey alone. Utilize smart collaboration to help you get from idea to MVP as quickly as possible. Share with stakeholders both internal and external and gather feedback in real-time to supercharge your design flow.
    "
    pages={[
      "A complete sign-up / log-in flow",
      "An account settings screen",
      "A dashboard showing accounts balance, and listing transactions",
      "A screen to initiate transfers",
      "A dedicated screen with an overview of all transactions",
      "A screen with an overview of accounts & cards",
      "A scren highlighting the performance of an investment portfolio",
      "And more!",
    ]}
    projectCode="XXpeaOpYpacqZbePVymJ"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Plutus, an online banking web app: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Plutus, an online banking web app: transactions overview page"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Plutus, an online banking web app: accounts and cards page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Plutus, an online banking web app: investment portfolio page"
    img5={data.image5.childImageSharp}
    img5alt="Image summary for the template Plutus, an online banking web app"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/online-banking-web-app/Online-Banking-Web-App-Cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/online-banking-web-app/Online-Banking-Web-App-Transactions.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/online-banking-web-app/Online-Banking-Web-App-Accounts-and-Cards.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/online-banking-web-app/Online-Banking-Web-App-Investments.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/online-banking-web-app/Online-Banking-Web-App-Summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
